/**Desktop Query*/
@media only screen and (min-width: 768px) {
  .table-section {
    margin-left: 250px;
  }

  .table-ribbon {
    margin-right: 20px;
    margin-top: 10px;

    display: flex;
    align-items: center;
    float: right;
  }

  .card-ribbon {
  //  margin-right: 20px;
    margin-top: 10px;

    display: flex;
    align-items: center;
    float: right;
  }
}

.ant-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .table-ribbon {
    margin-right: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    float: right;
  }

  .card-ribbon {
 //   margin-right: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    float: right;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .table-section {
    margin-left: 10px;
  }

  .table-ribbon {
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    float: right;
  }

  .card-ribbon {
  //  margin-right: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    float: right;
  }
}

.table-more-dropdown {
  font-weight: 400;
  font-family: "Avenir Next";
  font-size: 16px;
  width: 100%;
  color: rgb(61, 70, 77);
  padding-top: 2.5px;
}

.pop-out {
  /* position: absolute; */
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99 !important;
  /* border: 4px solid #2D7FF9; */
  /* border-radius: 6px; */
}

.table-section-text {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 15px;
  border: none;
  color: rgb(78, 78, 78);
  background-color: rgba(0, 0, 0, 0);
  font-family: "Avenir Next";
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  padding: 5px;
}

table thead {
  display: table;
  width: 100%;
  //  background-color: lightblue;
  //  position: fixed;
}

table {
  table-layout: fixed;
}

th {
  white-space: nowrap;
  max-width: 300px;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

td {
  white-space: nowrap;
  max-width: 300px;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-footer-row {
  display: flex;
  padding-top: 5px;
}

.table-area {
  box-sizing: content-box;
  padding-right: 30px;
  height: 100%;
  width: 100%;
  outline: none;
  position: relative;
}

.table-section-header {
  //  position: absolute;
  width: 100%;
  // overflow-x: auto;
  padding: 10px;

  font-size: 16px;
  font-family: Avenir Next;
}

.block {
  display: block;
}

.group-header-component {
  display: flex;
  position: relative;
}

.column-header {
  position: relative;
  min-width: 0px;
  text-align: center;
  font-size: 13px;
  color: #333;
  min-height: 40px;
  padding: 10px 0 0;
  border-right: 1px solid #fff;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: rgb(246, 249, 252);
  color: rgb(82, 95, 127);

  flex-basis: 140px;
  // max-width: 140px;

  border: 1px solid #eeeeee;
}

.column-header .column-header-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 5px;
}

.table-row {
  display: flex;
  height: 50px;
  position: relative;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.highlight {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #79b0fa99;
  z-index: 2;
}

.base-z {
  z-index: 1;
}

.table-cell {
  //  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  border-right: 1px solid #fff;
  color: #666;
  position: relative;

  flex-basis: 140px;
  // max-width: 140px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  border: 1px solid #eeeeee;
  border-bottom: 1px solid #e8ecee;
}

// Don't allow user interaction with the table
.table-cell.legacy {
  pointer-events: none;
  opacity: 0.75;
}

.summary-cell {
  background-color: transparent;
  border: none;
}

.type-title {
  color: #bebebe;
  font-size: 12px;
  margin-left: 5px;
}

.summary-cell-active {
  /*box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px; */
  /* border-radius: 8px; */
  background-color: #ffffff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  border: 1px solid #eeeeee;
  border-bottom: 1px solid #e8ecee;
}

.cell-active {
  overflow: hidden;
}

.new-row-cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  /* text-align: center; */
  font-size: 13px;
  font-weight: 400;
  border-right: 1px solid #fff;
  color: #bdbdbd;
  position: relative;
  flex-basis: 140px;
  display: flex;
  height: 40p;
  align-items: center;
  /* justify-content: center; */
  background-color: #ffffff;
  padding-left: 18px;
  border: 1px solid #e8ecee;
  border-bottom: 1px solid #e8ecee;
  cursor: pointer;
}

.new-row-cell:hover {
  background-color: #f3f3f3;
}

.footer {
  flex-basis: 60px;
  max-width: 60px;
  flex-grow: 0;
  flex-shrink: 0;

  //  border-top-right-radius: 8px;
  //   border-bottom-right-radius: 8px;
}

.index-row {
  //  position: absolute;
  //  z-index: 1;
  //   left: 0;
  //   width: 1000px;
  //  border-right: 2px solid #eff0ff;
  //  border-top-left-radius: 8px;
  //  border-bottom-left-radius: 8px;
}

.group-dropdown {
  display: inline;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 5px;
}

.section-header {
  text-align: left;
  width: 150px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.section-header-text {
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 15px;
  border: none;
  color: rgb(78, 78, 78);
  background-color: rgba(0, 0, 0, 0);
  font-family: "Avenir Next";
  text-align: left;
  text-overflow: ellipsis;
  padding: 5px;
  text-align: left;
  resize: none;
  text-overflow: ellipsis;
  border-radius: 6px;
  padding: 6px;
  width: 400px;
}

.section-header-text:hover {
  padding: 5px;
  border: 1px dashed #dadada;
}

.section-header-text:focus {
  padding: 5px;
  border: 1px dashed #dadada;
}

.section-empty-message {
  padding: 20px;
  color: #c2c2c2;
}

.filter-button {
  color: #6f6f6f;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #c3c3c3;
  padding: 8px 16px;
  font-weight: 500;
}

.table-skeleton-wrapper {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.toggle-button {
  font-weight: 600;
  padding: 8px;
  color: #6f6f6f;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 17px;
  display: flex;
  padding: 8px 16px;
  border: 1px solid #c3c3c3;
}

.toggle-icon {
  height: 15px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
}

.toggle-inline-button-icon {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 15px;
  margin-right: 8px;
  height: 15px;
}

.toggle-inline-button {
  display: flex;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 9px 14px;
}

.selected {
  cursor: not-allowed;
  background-color: #e9f5ff;
}

.toggle-inline-button-check {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 48px;
  height: 13px;
}

.toggle-inline-button:hover {
  background-color: #ececec;
  padding: 9px 14px;
  border-radius: 5px;
}

.toggle-menu {
  margin-left: -9px;
  margin-right: -5px;
}

.toggle-arrow {
  margin-left: 6px;
  height: 8px;
  margin-right: -5px;
  margin-top: auto;
  margin-bottom: auto;
}


/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .section-header-text {
    font-size: 21px;
    font-weight: 500;
    padding-bottom: 15px;
    border: none;
    color: rgb(78, 78, 78);
    background-color: rgba(0, 0, 0, 0);
    font-family: "Avenir Next";
    text-align: left;
    text-overflow: ellipsis;
    padding: 5px;
    text-align: left;
    resize: none;
    text-overflow: ellipsis;
    border-radius: 6px;
    padding: 6px;
    width: 400px;
  }

  .group-dropdown {
    display: inline;
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-right: 0px;
  }

  .table-cell {
    font-size: 12px;
  }

  .toggle-button {
    font-weight: 600;
    padding: 8px;
    color: #6f6f6f;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 17px;
    display: flex;
    padding: 8px 15px;
    border: 1px solid #c3c3c3;
    font-size: 12px;
  }

  .toggle-icon {
    height: 12px;
  }

  .toggle-arrow {
    height: 6px;
  }

}

.subtask-dropdown {
  visibility: hidden;
}

.subtask:hover .subtask-dropdown {
  visibility: visible;
}

.table-cell .mini-comment {
  display: none;
}

.table-cell:hover .mini-comment {
  display: flex;
}

.table-cell .dropdown-mini {
  visibility: hidden;
  display: none;
}

.table-cell .ext-mini {
  visibility: visible;
  display: flex;
}

.table-cell:hover .dropdown-mini {
  visibility: visible;
  display: flex;
}

.table-cell:hover .ext-mini {
  visibility: hidden;
  display: none;
}


.table-cell .subtask-mini {
  visibility: hidden;
}

.table-cell:hover .subtask-mini {
  visibility: visible;
}