.settings-section-inner {
    text-align: left;
    padding: 32px;
    /* margin-left: 20%; */
    /* margin-right: 20%; */
    /* display: inline-block; */
    border-radius: 8px;
    background-color: #ffffff;
    /* border: 1px solid #dadce0; */
    box-shadow: 1px 2px 6px #e0e0e0;
    display: flex;
    flex-direction: column;
  }

  .settings-section-header {
    font-size: 20px;
    font-family: Avenir Next;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .settings-section {
    text-align: left;
    /* padding: 32px; */
    /* margin-left: 20%; */
    /* margin-right: 20%; */
    display: inline-block;
    width: 100%;
    margin-top: 25px;
  }

  .input-box-with-title {
    padding: 0px 10px;
    margin-bottom: 20px;
  }

  .delete-section-button {
    padding: 10px 20px;
    background-color: #ff424c;
    color: #ffffff;
    display: inline-block;
    border-radius: 6px;
    margin-bottom: 20px;
    cursor: pointer;
  }