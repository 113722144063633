.label-selector-container {
  display: flex;
  flex-direction: column;

  font-size: 13px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  border-radius: 8px;
}

.label-selector-search {
  padding: 7px;
  border-bottom: 2px solid #ebebeb;
  display: flex;
  align-items: center;
}

.label-selector-search-icon {
  height: 17px;
  margin-right: 5px;
  color: #b1b1b1;
}

.label-selector-search-input {
  border: none;
  outline: none;
  font-size: 13px;
}

.label-selector-options {
  padding: 5px 0px;
  max-height: 200px;
  overflow-y: auto;
}

.label-selector-option {
  padding: 7px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-label {
  padding: 2px 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8;
  border-radius: 14px;
  font-size: 12px;
}

.label-selector-option-hashtag {
  margin-right: 5px;
}

.label-selector-option:hover {
  background-color: #f1f1f1;
}

/* Create label styles */

.create-label-container {
  padding: 1em;
}

.label-input-container {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.lable-input-title {
  margin-bottom: 0.45em;
  font-size: 14px;
}

.label-name-input {
  border-radius: 6px;
  border: 1px solid #cfcfcf;
  padding: 4px 6px;
  width: 100%;
}

.color-picker-container {
  position: absolute;
  z-index: 500;
}

.color-picker-color {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.color-picker-color-outer {
  cursor: pointer;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid #dbdbdb;
  width: fit-content;
  height: fit-content;
}

.color-picker-color-outer:hover {
  border: 2px solid #424242;
}

.create-label-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}

.create-label-cancel {
  margin-right: 0.5em;
  cursor: pointer;
}

.create-label-create {
  padding: 4px 6px;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  border-radius: 6px;
}

.label-color-picker {
  margin-right: 10px;
}

.create-label-button-container {
  padding: 7px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #640ff5;
}

.create-label-button-container.selected {
  background-color: #ededed;
}

.create-label-button-container:hover {
  background-color: #f1f1f1;
}

.create-label-icon {
  height: 15px;
  margin-right: 4px;
}

.labels-empty {
  text-align: center;
  padding: 10px;
}

.card-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #4c4c4c;

  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-popover-inner {
  border-radius: 10px;
}

.edit-labels-arrow-icon {
  height: 15px;
  margin-left: 4px;
}

#label-popover .ant-popover-inner-content {
  padding: 0px !important;
  border-radius: 6px !important;
}

#label-popver {
  border-radius: 10px !important;
}

/*
.card-no-label {
  display: none;
} */

.card-container:hover .card-no-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #4c4c4c;
  color: #7a7a7a;
  font-weight: 500;
}

.card-container:hover .card-no-label:hover {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #000000;
  font-weight: 500;
}

.card-no-label.visible {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #000000;
  font-weight: 500;
}

.lm-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  padding: 0px 8px;
}

.lm-title {
  font-size: 16px;
  font-weight: 500;
}

.lm-create-button {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #640ff5;
  font-size: 15px;
  cursor: pointer;
}

.lm-create-button:hover {
  color: #000000;
}

.lm-create-button .icon {
  height: 18px;
  margin-right: 5px;
}

.create-label-container.inline {
  padding: 10px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.label-input-container.inline {
  margin-bottom: 0px;
}

.label-selector-option.selected {
  background-color: #ededed;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .label-selector-search {
    background-color: #ededed;
    border-radius: 10px;
    padding: 10px;
  }

  .label-selector-search-input {
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 500;
  }

  .label-selector-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    font-size: 16px;
    padding: 0px 10px 10px 10px;
  }

  .label-selector-options {
    display: flex;
    flex-direction: column;
    margin: 0px;
    font-size: 16px;
    padding: 10px;
  }

  .label-selector-option {
    padding: 4px 0px;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-bottom: 10px;
  }

  .label-box {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.active-labels {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}

.label-box {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 8px;
}

.label-selector-option-check-icon {
  width: 14px;
  height: 14px;
  color: #640ff5;
}

.label-content {
  display: flex;
  align-items: center;
}

.floating-done-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  color: #000000;
  border-top: 1px solid #d1d1d1;
  margin-top: 11px;
  /* background-color: #9900ef; */
  /* cursor: pointer; */
}

.done-button {
  padding: 4px 6px;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  border-radius: 6px;
}

.topbar-button {
  padding: 8px 17px;
  font-size: 14px;
  font-weight: 500;
  font-family: Avenir Next;
  cursor: pointer;
  border-radius: 5px;
  color: rgba(31, 34, 37, 0.9);
  background: rgba(31, 34, 37, 0);
  box-shadow: rgba(31, 34, 37, 0.09) 0px 0px 0px 1px inset;
  font-weight: 500;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 15px;
}

.topbar-button.filter.enabled {
  background: hwb(218deg 92% 0%);
  /* box-shadow: rgba(31, 34, 37, 0.09) 0px 0px 0px 1px inset; */
  color: #640ff5;
  border: 1px solid #0090ff;
}

.topbar-button:hover {
  color: #000000;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 1px inset;
}

.labels {
  margin-right: 10px;
}

.labels-title {
  margin-bottom: 0.25em;
}

.labels-container {
  overflow-y: auto;
  min-width: 150px;
  /* max-height: 120px; */
  flex: 1 1 auto;
  padding: 4px 6px 0px 6px;
}

.label-container {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 6px;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #565656;
}

.label {
  display: flex;
  align-items: center;
}

.label-box {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
  min-width: 10px;
  min-height: 10px;
}

.card-label span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-container:hover {
  background-color: #f1f1f1;
}

.label-container.selected {
  background-color: #f1f1f1;
  cursor: pointer;
}

.label-selected-icon {
  height: 14px;
  color: #ababab;
}

.label-filter-button {
  /* background-color: #000000; */
  padding: 8px 16px;
  /* border-radius: 25px; */
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  /* margin-top: 10px; */
}

.label-filter-button:hover {
  color: #272ee8;
}

.floating-labels-button-icon {
  height: 18px;
  margin-right: 8px;
}

.floating-button-chevron {
  margin-left: 8px;
  height: 18px;
}

.labels-filter-container {
  background-color: #ffffff;
  border-radius: 6px;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  display: flex;
  border-radius: 7px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25);
  /* max-height: 250px; */
}

/* Updating label styles */

.update-label-container {
  display: flex;
  align-items: center;
  padding: 9px 8px;
  justify-content: space-between;
}

.delete-label-icon {
  height: 19px;
  color: #bbbbbb;
  cursor: pointer;
}

.delete-label-icon:hover {
  color: #000000;
}

.update-label-fields {
  display: flex;
  align-items: center;
  flex: auto;
  margin-right: 10px;
}

.lm-close {
  cursor: pointer;
  height: 20px;
  color: gray;
}

.lm-close:hover {
  color: black;
}

.label-container.edit {
  font-weight: 500;
  color: #640ff5;
  font-size: 13px;
}

.edit-labels-arrow-icon {
  height: 15px;
  margin-left: 4px;
}

.label-delete-icon {
  height: 15x;
  width: 15px;
  margin-left: 5px;
  color: black;
  cursor: pointer;
}

.label-delete-icon:hover {
  color: red;
}
