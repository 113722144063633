@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media only screen and (min-width: 1900px) {
  .home {
    margin-left: 10%;
    margin-right: 10%;
  }
}

/**Desktop Query*/
@media only screen and (min-width: 768px) and (max-width: 1900px) {
  .home {
    margin-left: 10%;
    margin-right: 10%;
  }

  .home.compact-mode {
    margin-left: 20px;
    margin-right: 5px;
  }
}

/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  body {
    font-size: 12px;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .home {
    margin-left: 1%;
    margin-right: 1%;
  }
}

/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .home {
    margin-left: 5%;
    margin-right: 5%;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .home {
    margin-left: 0px;
    margin-right: 0px;
  }
}
