.field {
  text-align: left;
  font-size: 16px;
  font-family: "Avenir Next";
  font-weight: 500;
}

.fieldTitle {
  margin-top: 19px;
  color: #676767;
}

.tag {
  padding: 6px 10px;
  margin-top: 7px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  // border: 1px solid;
}

.fieldValue {
  font-weight: 400;
  margin-top: 7px;
  padding: 7px;
  color: rgb(97, 97, 97);
  margin-left: 8px;
  white-space: pre-wrap;
  outline: none;

  overflow-wrap: break-word;
}

.passwordField {
  font-weight: 400;
  margin-top: 7px;
  padding: 7px;
  color: rgb(97, 97, 97);
  margin-left: 8px;
  white-space: pre-wrap;
  outline: none;
  display: inline-block;
}

.passwordFieldHidden {
  font-size: 16px;
  font-weight: 400;
  margin-top: 7px;
  padding: 7px;
  margin-left: 8px;
  font-family: "Avenir Next";
  color: #000000;
  filter: blur(2.5px);
  display: inline-block;
}

.textArea {
  line-height: 23px;
  widows: 100%;
  background-color: #ffffff;
  margin-top: 7px;
  widows: 100%;
  resize: none;
  border-radius: 7px;
  padding: 10px;
  color: rgb(97, 97, 97);
  border: 1px solid #d6d6d6;
  padding-bottom: 20px;
  white-space: pre-wrap;
  outline: none;
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 16px;
}

.dropdown {
  width: 300px;
  margin-top: 7px;
}

.attachments {
  //  margin-top: 10px
}

.hairline-button {
  color: rgb(26, 115, 232);
  border: 0.7px solid rgb(227, 225, 225);
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.hairlineButton,
.reveal-button {
  display: inline-block;
  margin-left: 20px;
}

.collaborators-container {
  position: relative;
  /* position: absolute; */
  top: 0;
  width: 100%;
  z-index: 99 !important;
  /* border: 4px solid #2D7FF9; */
  /* border-radius: 6px; */
}

.users-container {
  display: block;
  padding: 10px;
  text-align: left;
  /* border: 3px solid #2d7ff9; */
  background-color: white;
  border-radius: 6px;
}

.card-chip {
  background-color: #eeeeee;
  border-radius: 20px;
  padding-right: 10px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 0px;

  margin-bottom: 10px;
  margin-right: 11px;
}


/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .textArea {
    line-height: 23px;
    widows: 100%;
    background-color: #ffffff;
    margin-top: 7px;
    widows: 100%;
    resize: none;
    border-radius: 7px;
    padding: 10px;
    color: rgb(97, 97, 97);
    border: 1px solid #d6d6d6;
    padding-bottom: 20px;
    white-space: pre-wrap;
    outline: none;
    font-family: "Avenir Next";
    font-weight: 500;
    font-size: 13px;
  }
}

.required-chip {
  font-size: 11px;
  border-radius: 17px;
  background-color: #e5e5e5;
  padding: 4px 10px;
  margin-left: 5px;
  color: #585858;
  cursor: pointer;
}


.legacy-chip {
  font-size: 11px;
  border-radius: 17px;
  background-color: #e5e5e5;
  padding: 4px 10px;
  margin-left: 5px;
  color: #585858;
  cursor: pointer;
}

.legacy-chip.table {
  font-size: 11px;
  border-radius: 17px;
  background-color: #004eff;
  padding: 1px 8px;
  margin-left: 5px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
}
