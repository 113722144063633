.field {
  text-align: left;
  font-size: 16px;
  font-family: "Avenir Next";
  font-weight: 500;
}

.fieldTitle {
  margin-top: 19px;
  color: #676767;
}

.table-tag {
  padding: 6px 10px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  border: 1px solid;
  min-width: 150px;
  text-align: center;
  margin: none;
  cursor: pointer;
}

.dateValue {
  cursor: pointer;
  font-size: 13px;
}

.table-input {
  font-weight: 400;
  width: 100%;
  border: none;
  font-family: "Avenir Next";
  font-size: 15px;
  text-align: left;
  margin-left: 3px;
  resize: none;

  text-overflow: ellipsis;
  border-radius: 6px;
  padding: 5px;
  border: none;
}

/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .table-input {
    font-weight: 400;
    width: 100%;
    border: none;
    font-family: "Avenir Next";
    font-size: 13px;
    text-align: left;
    margin-left: 3px;
    resize: none;

    text-overflow: ellipsis;
    border-radius: 6px;
    padding: 5px;
    border: none;
  }

  .table-tag {
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 11px;
    display: inline-block;
    border: 1px solid;
    min-width: 120px;
    text-align: center;
    margin: none;
    cursor: pointer;
  }
}

.table-input:hover {
  border: 1px dashed #dadada;
}

.fieldValue {
  font-weight: 400;
  color: rgb(97, 97, 97);
  white-space: pre-wrap;
  outline: none;
}

.passwordField {
  font-weight: 400;
  margin-top: 7px;
  padding: 7px;
  color: rgb(97, 97, 97);
  margin-left: 8px;
  white-space: pre-wrap;
  outline: none;
  display: inline-block;
}

.passwordFieldHidden {
  font-size: 16px;
  font-weight: 400;
  margin-top: 7px;
  padding: 7px;
  margin-left: 8px;
  font-family: "Avenir Next";
  color: #000000;
  filter: blur(2.5px);
  display: inline-block;
}



.expanded-input {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 192px;
  min-width: 100%;
  max-width: 100%;
  border: 0;
  padding: 10px 10px;
  font-size: 14px;
  border: 1px solid #c4c4c4;
  outline: none;
  color: #333333;
  border: 3px solid #2d7ff9;
  border-radius: 6px;
  font-family: "Avenir Next";
  font-size: 15px;
}

.single-line {
  max-height: 48px;
  min-height: 48px;
}

.dropdown {
  width: 300px;
  margin-top: 7px;
}

.hairline-button {
  color: rgb(26, 115, 232);
  border: 0.7px solid rgb(227, 225, 225);
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.hairlineButton,
.reveal-button {
  display: inline-block;
  margin-left: 20px;
}

.full-width {
  flex: 1;
}

.cell-edit-button {
  position: absolute;
  color: #a9a9a9;
  cursor: pointer;
  right: 0;
  margin-right: 10px;
}

.cell-add-button {
  color: #a9a9a9;
  cursor: pointer;
  right: 0;
}

.user-chip {
  background-color: #eeeeee;
  border-radius: 20px;
  padding-right: 10px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 0px;
}

.active {
  margin-bottom: 8px;
}

.users {
  // display: flex;
  display: flex;
  padding: 10px;
  text-align: left;
  border-radius: 6px;
  cursor: pointer;
}

.users-active {
  display: block;
  padding: 10px;
  text-align: left;
  border: 3px solid #2d7ff9;
  background-color: white;
  border-radius: 6px;
}

.add-button {
  height: 25px;
  width: 25px;
  border-radius: 10px;
  cursor: pointer;
}

.delete-button {
  height: 9px;
  /* width: 25px; */
  cursor: pointer;
  margin-left: 8px;
}

.user-image {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
}

.component-image {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 5px;
  vertical-align: middle;
}

.user-name {
  font-size: 14px;
  font-weight: 500;
  margin-left: 7px;
  vertical-align: middle;
}

.single-line {
  max-height: 48px;
  min-height: 48px;
}

/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .field {
    text-align: left;
    font-size: 14px;
    font-family: "Avenir Next";
    font-weight: 500;
  }
}