.change-container {
  font-family: Avenir Next;
  white-space: pre-wrap;
  color: rgb(36, 41, 46);
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.field-text {
  font-weight: 500;
}

.change-before {
  background-color: #ede2fe;
  color: #000000;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
}

.change-after {
  background-color: #d0f0fe;
  color: #000000;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
}

.message-bubble-name {
  font-family: "Avenir Next";
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px;
  text-decoration: none;
  float: left;
}

.quick-switcher {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 100;
  background: #313131;
  border: 1px solid #ececec;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-width: 500px;
  color: #ffffff;
}

.qs-search-container {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #797979;
}

.qs-results {
  max-height: 500px;
  overflow-y: scroll;
}

.qs-search-input {
  width: 100%;
  border: none;
  background-color: #313131;
}

.qs-search-input:focus {
  width: 100%;
  border: none;
  background-color: #313131;
  outline: none;
}

.qs-result {
  padding: 10px;
  cursor: pointer;
}

.qs-result:hover {
  padding: 10px;
  cursor: pointer;
  background-color: #4a4a4a;
}

.qs-search-icon {
  border-style: none;
  height: 20px;
  margin-right: 10px;
}

.switcher-selected {
  background-color: #5a5a5a;
}

.qs-result:focus {
  padding: 10px;
  background-color: #5a5a5a;
}

.menu-item {
  display: flex;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  border-radius: 6px;
}

.card-style {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.card-style-dash {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.activity-bubble-name {
  font-family: "Avenir Next";
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px;
  text-decoration: none;
  float: left;
}

.bubble-timestamp {
  margin-left: 7px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(147, 147, 147);
}

.comment-content {
  font-family: "Avenir Next";
  margin-top: 3px;
  font-size: 16px;
  border-radius: 6px;
  color: rgb(31, 41, 46);
  white-space: pre-wrap;
  display: inline-block;
  max-width: 95%;
  background-color: rgb(242, 243, 245);
  padding: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}

/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .card-style {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
  }

  .comment-content {
    font-family: "Avenir Next";
    margin-top: 3px;
    font-size: 14px;
    border-radius: 6px;
    color: rgb(31, 41, 46);
    white-space: pre-wrap;
    display: inline-block;
    max-width: 95%;
    background-color: rgb(242, 243, 245);
    padding: 13px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .card-style-dash {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
  }

  .menu-item {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 7px;
    font-size: 14px;
    border-radius: 6px;
  }

  .message-bubble-name {
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px;
    text-decoration: none;
    float: left;
  }

  .activity-bubble-name {
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px;
    text-decoration: none;
    float: left;
  }

  .bubble-timestamp {
    margin-left: 7px;
    font-weight: 400;
    font-size: 13px;
    color: rgb(147, 147, 147);
  }

  .change-before {
    background-color: #ede2fe;
    color: #000000;
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    font-size: 12px;
  }

  .change-after {
    background-color: #d0f0fe;
    color: #000000;
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    font-size: 12px;
  }

  .field-text {
    font-size: 14px;
  }
}
